define("songbox/pods/lists/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('list');
    },
    actions: {
      select: function select(list) {
        return this.transitionTo('list', list);
      },
      remove: function remove(list) {
        return list.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});