define("songbox/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app": {
      "link-account": "Account",
      "link-lists": "Lists",
      "link-songs": "Songs"
    },
    "auth.login": {
      "button": "Login",
      "flash-200": "Successfully logged in!",
      "flash-401": "There was a problem with your username or password, please try again",
      "flash-500": "Server Error"
    },
    "auth.register": {
      "button": "Register"
    },
    "list": "{count, plural, =1 {1 list} other {# lists}}",
    "list.edit": {
      "header": "Edit List \"{name}\""
    },
    "list.name": "Name",
    "lists": {
      "header": "Lists",
      "link-edit": "Edit",
      "link-new": "New"
    },
    "lists.new": {
      "header": "Add a new List"
    },
    "song": "{count, plural, =1 {1 song} other {# songs}}",
    "song.author": "Author",
    "song.ccli": "CCLI",
    "song.edit": {
      "header": "Edit Song \"{title}\""
    },
    "song.files": "Files",
    "song.format": "Format",
    "song.index": {
      "link-edit": "Edit"
    },
    "song.key": "Key",
    "song.license": "License",
    "song.tempo": "Tempo",
    "song.text": "Text",
    "song.time": "Time",
    "song.title": "Title",
    "songs": {
      "import.header": "Import",
      "link-edit": "Edit",
      "link-import": "Import",
      "link-new": "New"
    },
    "songs.index": {
      "text": "Select a song"
    },
    "songs.new": {
      "header": "Add a new Song"
    },
    "user": {
      "email": "Email",
      "password": "Password",
      "password-confirmation": "Confirmation"
    }
  };
  _exports.default = _default;
});