define("songbox/serializers/application", ["exports", "ember-data/serializers/json-api", "ember-inflector"], function (_exports, _jsonApi, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    }
  });

  _exports.default = _default;
});