define("songbox/validations/user", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    email: (0, _validators.validateFormat)({
      type: 'email'
    }),
    password: (0, _validators.validateLength)({
      min: 8,
      max: 24
    }),
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: 'password'
    })
  };
  _exports.default = _default;
});