define("songbox/pods/list/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6Vf5tlpY",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"sidebar\",[27,\"component\",[\"list-songs-add-sidebar\"],[[\"onSelect\",\"onAdd\",\"list\",\"songs\"],[[27,\"route-action\",[\"select\",[23,[\"model\",\"list\"]]],null],[27,\"route-action\",[\"add\",[23,[\"model\",\"list\"]]],null],[23,[\"model\",\"list\"]],[23,[\"model\",\"songs\"]]]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"model\",\"song\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"em-header\",null,[[\"center\"],[true]],{\"statements\":[[0,\"Add Song on the left side.\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"song-sheet\",null,[[\"song\"],[[23,[\"model\",\"song\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/list/add/template.hbs"
    }
  });

  _exports.default = _default;
});