define("songbox/initializers/route-classes", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // https://dockyard.com/blog/ember/2013/03/27/body-class-tags-in-ember
  function initialize()
  /*owner*/
  {
    Ember.Route.reopen({
      activate: function activate() {
        var cssClass = this.toCssClass(); // you probably don't need the application class
        // to be added to the body

        if (cssClass === 'application') {
          return;
        }

        if (typeof FastBoot === "undefined") {
          (0, _jquery.default)('body').addClass(cssClass);
        }
      },
      deactivate: function deactivate() {
        if (typeof FastBoot === "undefined") {
          (0, _jquery.default)('body').removeClass(this.toCssClass());
        }
      },
      toCssClass: function toCssClass() {
        return this.routeName.replace(/\./g, '-').dasherize();
      }
    });
  }

  var _default = {
    name: 'route-classes-browser',
    initialize: initialize
  };
  _exports.default = _default;
});