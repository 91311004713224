define("songbox/pods/components/modal-target/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hWD3qQqn",
    "block": "{\"symbols\":[\"modal\",\"currentModal\"],\"statements\":[[4,\"from-elsewhere\",null,[[\"name\"],[\"modal\"]],{\"statements\":[[4,\"liquid-bind\",[[22,1,[]]],[[\"containerless\",\"use\"],[true,[23,[\"modalAnimation\"]]]],{\"statements\":[[4,\"if\",[[22,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"modal-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-background\"],[11,\"role\",\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[22,2,[\"onOutsideClick\"]]],null]],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"modal-dialog\"],[9],[0,\"\\n          \"],[7,\"h1\"],[11,\"class\",\"modal-title\"],[9],[1,[22,2,[\"title\"]],false],[10],[0,\"\\n          \"],[1,[27,\"component\",[[22,2,[\"body\"]]],null],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/modal-target/template.hbs"
    }
  });

  _exports.default = _default;
});