define("songbox/pods/auth/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "InQfO/9H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"user-register-form\",null,[[\"changeset\",\"onSubmit\"],[[23,[\"changeset\"]],[27,\"route-action\",[\"doRegister\",[23,[\"changeset\"]]],null]]],{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"auth.login\"],null,{\"statements\":[[0,\"Login\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/auth/register/template.hbs"
    }
  });

  _exports.default = _default;
});