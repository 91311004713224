define("songbox/pods/components/settings-switch/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SettingsSwitch = Ember.Component.extend({
    settings: Ember.inject.service(),
    key: null,
    setting: Ember.computed('key', {
      get: function get()
      /*key*/
      {
        var key = this.key;
        return this.get("settings.".concat(key));
      },
      set: function set(_key, value) {
        var key = this.key;
        this.set("settings.".concat(key), value);
        return value;
      }
    })
  });
  SettingsSwitch.reopenClass({
    positionalParams: ['key']
  });
  var _default = SettingsSwitch;
  _exports.default = _default;
});