define("songbox/pods/app/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C2fHBJc0",
    "block": "{\"symbols\":[\"burger\"],\"statements\":[[4,\"if\",[[23,[\"editUser\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"modal\",[27,\"hash\",null,[[\"title\",\"body\",\"onOutsideClick\"],[\"Account\",[27,\"component\",[\"user-edit-form\"],[[\"changeset\",\"onSubmit\",\"onCancel\",\"onLogout\"],[[23,[\"changeset\"]],[27,\"route-action\",[\"saveUser\",[23,[\"changeset\"]]],null],[27,\"route-action\",[\"cancelUser\",[23,[\"changeset\"]]],null],[27,\"route-action\",[\"logout\"],null]]]],[27,\"route-action\",[\"cancelUser\",[23,[\"changeset\"]]],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"burger-menu\",null,[[\"open\",\"animation\",\"width\",\"translucentOverlay\",\"dismissOnClick\"],[[23,[\"showMenu\"]],\"push\",300,false,false]],{\"statements\":[[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"menu\"]],\"expected `burger.menu` to be a contextual component but found a string. Did you mean `(component burger.menu)`? ('songbox/pods/app/template.hbs' @ L17:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"app-sidebar\",null,[[\"onClose\"],[[27,\"action\",[[22,0,[]],[22,1,[\"state\",\"actions\",\"toggle\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"outlet\"]],\"expected `burger.outlet` to be a contextual component but found a string. Did you mean `(component burger.outlet)`? ('songbox/pods/app/template.hbs' @ L21:C5) \"],null]],null,{\"statements\":[[4,\"app-content\",null,[[\"onToggle\"],[[27,\"action\",[[22,0,[]],[22,1,[\"state\",\"actions\",\"toggle\"]]],null]]],{\"statements\":[[0,\"      \"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/app/template.hbs"
    }
  });

  _exports.default = _default;
});