define("songbox/pods/components/song-sheet/component", ["exports", "songbox/utils/parsers"], function (_exports, _parsers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    song: null,
    showChords: true,
    showSectionHeaders: true,
    showMultiColumn: false,
    sections: Ember.computed('song.{text,format}', function () {
      var parser = _parsers.default[this.get('song.format') || 'opensong'];

      return parser(this.get('song.text'));
    })
  });

  _exports.default = _default;
});