define("songbox/pods/components/item-list/list-items/list-item/item-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q4VQvaCr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"title\",\"details\"],[[27,\"component\",[\"item-list/list-items/list-item/item-text/item-title\"],null],[27,\"component\",[\"item-list/list-items/list-item/item-text/item-details\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/list-items/list-item/item-text/template.hbs"
    }
  });

  _exports.default = _default;
});