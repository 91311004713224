define("songbox/services/current", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    userId: null,
    user: Ember.computed('userId', function () {
      return this.store.peekRecord('user', this.userId);
    }),
    room: Ember.computed.readOnly('user.room'),
    load: function load() {
      var _this = this;

      return this.ajax.request('/users/current').then(function (json) {
        _this.store.pushPayload(json);

        _this.set('userId', json.data.id);

        return _this.user;
      });
    }
  });

  _exports.default = _default;
});