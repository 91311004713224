define("songbox/pods/song/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hiykUnbh",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"actions\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"song.edit\",[23,[\"model\"]]],[[\"title\"],[[27,\"t\",[\"song.index.link-edit\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"svg-icon\",[\"ic_mode_edit_24px\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[27,\"song-sheet\",null,[[\"song\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/song/index/template.hbs"
    }
  });

  _exports.default = _default;
});