define("songbox/pods/lists/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kDm+K+mk",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"sidebar\",[27,\"component\",[\"lists-sidebar\"],[[\"lists\",\"editMode\",\"onSelect\",\"onRemove\"],[[23,[\"model\"]],[23,[\"editMode\"]],[27,\"route-action\",[\"select\"],null],[27,\"route-action\",[\"remove\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/lists/template.hbs"
    }
  });

  _exports.default = _default;
});