define("songbox/pods/components/modal-target/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modalAnimation: modalAnimation
  });

  _exports.default = _default;

  function modalAnimation() {
    return this.lookup('explode').call(this, {
      pick: '.modal-background',
      use: ['fade', {
        maxOpacity: 0.5
      }]
    }, {
      pick: '.modal-dialog',
      use: 'scale'
    });
  }
});