define("songbox/pods/auth/login/route", ["exports", "songbox/mixins/routes/model-changeset", "songbox/validations/user-login"], function (_exports, _modelChangeset, _userLogin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modelChangeset.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    validator: _userLogin.default,
    model: function model() {
      return {
        modelName: 'user'
      };
    },
    actions: {
      doLogin: function doLogin(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            return _this.session.authenticate('authenticator:songbox', changeset.get('email'), changeset.get('password')).then(function () {
              var message = _this.intl.t('auth.login.flash-200');

              _this.flashMessages.success(message);
            }).catch(function (response) {
              var errors = response.errors;

              if (errors.mapBy('code').includes(401)) {
                var message = _this.intl.t('auth.login.flash-401');

                _this.flashMessages.danger(message);
              } else {
                var _message = _this.intl.t('auth.login.flash-500');

                _this.flashMessages.danger(_message);
              }
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});