define("songbox/pods/components/item-list/list-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yCH9+tex",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"filter\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"placeholder\",\"value\"],[\"Search by title...\",[23,[\"searchTerm\"]]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"Clear\"],[3,\"action\",[[22,0,[]],\"clearSearch\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[14,1,[[23,[\"filtered\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/list-filter/template.hbs"
    }
  });

  _exports.default = _default;
});