define("songbox/pods/components/song-sheet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/DvpOz8Q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[11,\"class\",\"song-title\"],[9],[1,[23,[\"song\",\"title\"]],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"song\",\"format\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"concat\",[\"song-sheet/\",[23,[\"song\",\"format\"]],\"-sheet\"],null]],[[\"sections\",\"showChords\",\"showSectionHeaders\",\"showMultiColumn\"],[[23,[\"sections\"]],[23,[\"showChords\"]],[23,[\"showSectionHeaders\"]],[23,[\"showMultiColumn\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  No format specified\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/song-sheet/template.hbs"
    }
  });

  _exports.default = _default;
});