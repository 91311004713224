define("songbox/pods/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XCYzdtqY",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[27,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"sidebar\",[27,\"component\",[\"list-items-sidebar\"],[[\"list\",\"selected\",\"editMode\",\"onSelect\",\"onRemove\",\"onReorder\"],[[23,[\"model\"]],[23,[\"selected\"]],[23,[\"editMode\"]],[27,\"route-action\",[\"select\",[23,[\"model\"]]],null],[27,\"route-action\",[\"remove\"],null],[27,\"route-action\",[\"reorder\"],null]]]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"editMode\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"list.edit.header\"],[[\"name\"],[[23,[\"model\",\"name\"]]]]],false],[0,\"\\n\\n  \"],[1,[27,\"list-form\",null,[[\"changeset\",\"onSave\",\"onCancel\"],[[23,[\"changeset\"]],[27,\"route-action\",[\"save\",[23,[\"changeset\"]]],null],[27,\"route-action\",[\"show\",[23,[\"model\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/list/template.hbs"
    }
  });

  _exports.default = _default;
});