define("songbox/models/list_item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    rank: attr('number'),
    // order column
    position: attr('number'),
    // set to specify position
    song: belongsTo('song'),
    list: belongsTo('list')
  });

  _exports.default = _default;
});