define("songbox/pods/components/list-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NqXllQYJ",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-for\",[[23,[\"changeset\"]]],[[\"submit\"],[[23,[\"onSave\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"text-field\"]],\"expected `f.text-field` to be a contextual component but found a string. Did you mean `(component f.text-field)`? ('songbox/pods/components/list-form/template.hbs' @ L2:C4) \"],null],\"name\"],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"submit\"]],\"expected `f.submit` to be a contextual component but found a string. Did you mean `(component f.submit)`? ('songbox/pods/components/list-form/template.hbs' @ L3:C4) \"],null],\"Save\"],[[\"disabled\"],[[27,\"or\",[[23,[\"changeset\",\"isPristine\"]],[23,[\"changeset\",\"isInvalid\"]]],null]]]],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"button\"]],\"expected `f.button` to be a contextual component but found a string. Did you mean `(component f.button)`? ('songbox/pods/components/list-form/template.hbs' @ L4:C4) \"],null],\"Cancel\"],[[\"classNames\",\"click\"],[\"\",[23,[\"onCancel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/list-form/template.hbs"
    }
  });

  _exports.default = _default;
});