define("songbox/pods/list/item/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pubsub: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      this.controllerFor('list').setProperties({
        selected: model.item
      }); // share song

      var channel = this.controllerFor('app').get('model.channel');
      var song = model.item.get('song');

      if (channel) {
        channel.push('share', song.getProperties('title', 'text'));
      }
    },
    resetController: function resetController(controller, isExiting
    /*, transition*/
    ) {
      if (isExiting) {
        this.controllerFor('list').setProperties({
          selected: null
        });
      }
    }
  });

  _exports.default = _default;
});