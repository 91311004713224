define("songbox/models/song", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    // attributes
    title: attr('string'),
    author: attr('string'),
    key: attr('string'),
    tempo: attr('number'),
    time: attr('string'),
    text: attr('string'),
    format: attr('string', {
      defaultValue: 'opensong'
    }),
    license: attr('string'),
    ccli: attr('number')
  });

  _exports.default = _default;
});