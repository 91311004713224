define("songbox/pods/components/item-list/list-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "31DP9bt5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"new\",\"add\",\"edit\"],[[27,\"component\",[\"item-list/list-actions/action-link\"],[[\"icon\",\"data-test-list-action-new\"],[\"ic_add_box_24px\",1]]],[27,\"component\",[\"item-list/list-actions/action-link\"],[[\"icon\",\"data-test-list-action-add\"],[\"ic_add_box_24px\",1]]],[27,\"component\",[\"item-list/list-actions/action-link\"],[[\"icon\",\"data-test-list-action-edit\"],[\"ic_mode_edit_24px\",1]]]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/list-actions/template.hbs"
    }
  });

  _exports.default = _default;
});