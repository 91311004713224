define("songbox/mixins/routes/dirty-changeset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        var changeset = this.get('controller.changeset');

        if (changeset.get('isPristine')) {
          return true;
        }

        var shouldDiscard = window.confirm('Do you want to discard your changes?');

        if (!shouldDiscard) {
          return transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});