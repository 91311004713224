define("songbox/pods/components/x-toasts/x-toast/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['material-toast', 'toast'],
    classNameBindings: ['active', 'exiting', 'color'],
    active: false,
    exiting: Ember.computed.readOnly('content.exiting'),
    color: Ember.computed('content.type', function () {
      switch (this.get('content.type')) {
        case 'danger':
          return 'red darken-2 white-text';

        case 'warning':
          return 'yellow lighten-1 black-text';

        default:
          return '';
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments); // Very shortly after a message is created, add the "active"
      // class to it, so that we can use CSS animations for
      // the entry transition


      this._applyActiveClass = Ember.run.next(function () {
        _this.set('active', true);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super(); // Prevent leaking


      this._destroyFlashMessage(); // To be thorough, we will cancel any queued
      // task to add the "active" class (see: didInsertElement)


      if (this._applyActiveClass) {
        Ember.run.cancel(this._applyActiveClass);
      }
    },
    _destroyFlashMessage: function _destroyFlashMessage() {
      var flash = Ember.getWithDefault(this, 'content', false);

      if (flash) {
        flash.destroyMessage();
      }
    }
  });

  _exports.default = _default;
});