define("songbox/pods/songs/import/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pQdl76SN",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[0,\"\\n\"],[4,\"em-header\",null,null,{\"statements\":[[1,[27,\"t\",[\"songs.import.header\"],null],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"form-for\",[[23,[\"model\"]]],[[\"submit\"],[[27,\"route-action\",[\"doImport\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"file-field\"]],\"expected `f.file-field` to be a contextual component but found a string. Did you mean `(component f.file-field)`? ('songbox/pods/songs/import/template.hbs' @ L5:C4) \"],null],\"files\"],[[\"multiple\"],[\"multiple\"]]],false],[0,\"\\n  \"],[4,\"em-note\",null,null,{\"statements\":[[0,\"We currently only support the Opensong file format.\"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"submit\"]],\"expected `f.submit` to be a contextual component but found a string. Did you mean `(component f.submit)`? ('songbox/pods/songs/import/template.hbs' @ L8:C4) \"],null],\"Save\"],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"button\"]],\"expected `f.button` to be a contextual component but found a string. Did you mean `(component f.button)`? ('songbox/pods/songs/import/template.hbs' @ L9:C4) \"],null],\"Cancel\"],[[\"classNames\",\"click\"],[\"\",[27,\"route-action\",[\"cancel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/songs/import/template.hbs"
    }
  });

  _exports.default = _default;
});