define("songbox/pods/viewer/room/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pubsub: Ember.inject.service(),
    model: function model() {
      return {
        title: 'Please wait...',
        format: 'opensong',
        text: ''
      };
    },
    setupController: function setupController(controller
    /*, model*/
    ) {
      if (typeof FastBoot === "undefined") {
        var pathElems = window.location.pathname.split('/');
        var roomId = pathElems[pathElems.length - 1];
        this.pubsub.connectViewer();
        this.pubsub.joinChannel("room:".concat(roomId), {
          type: 'Viewer'
        }).then(function (channel) {
          channel.on('share', function (resp) {
            controller.set('model', resp);
          });
        });
      }
    }
  });

  _exports.default = _default;
});