define("songbox/pods/components/item-list/list-items/list-item/component", ["exports", "ember-sortable/mixins/sortable-item"], function (_exports, _sortableItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_sortableItem.default, {
    tagName: 'li',
    classNameBindings: ['isSelected:active'],
    isSelected: Ember.computed('model.id', 'selected.id', function () {
      return this.get('model.id') === this.get('selected.id');
    })
  });

  _exports.default = _default;
});