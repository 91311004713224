define("songbox/pods/components/list-songs-add-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    list: null,
    songs: null,
    actions: {
      addSong: function addSong(song) {
        this.onAdd(song);
      },
      selectSong: function selectSong(song) {
        this.onSelect(song);
      }
    }
  });

  _exports.default = _default;
});