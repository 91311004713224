define("songbox/pods/list/item/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    flashMessages: Ember.inject.service(),
    model: function model(_ref) {
      var position = _ref.position;
      var list = this.modelFor('list');
      var item = list.get('listItems').sortBy('rank').objectAt(position - 1);
      return Ember.RSVP.hash({
        list: list,
        item: item
      });
    },
    afterModel: function afterModel(model) {
      if (!model.item) {
        this.flashMessages.danger('Item not found');
        this.transitionTo('list', model.list);
      }
    }
  });

  _exports.default = _default;
});