define("songbox/pods/app/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-changeset", "ember-changeset-validations", "songbox/validations/user"], function (_exports, _authenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pubsub: Ember.inject.service(),
    session: Ember.inject.service(),
    current: Ember.inject.service(),
    authenticationRoute: Ember.computed(function () {
      return 'auth.login';
    }),
    model: function model() {
      var _this = this;

      return this.current.load().then(function (user) {
        var channel = null;

        if (!_this._isFastBoot) {
          _this.pubsub.connectUser();

          var channelName = "room:".concat(user.get('room.uid'));
          channel = _this.pubsub.joinChannel(channelName);
        }

        return Ember.RSVP.hash({
          user: user,
          channel: channel
        });
      }).catch(function ()
      /*err*/
      {
        return _this.session.invalidate();
      });
    },
    // copied from 'AuthenticatedRouteMixin'
    afterModel: function afterModel(model, transition) {
      if (!this.get('session.isAuthenticated')) {
        if (!this._isFastBoot) {
          this.set('session.attemptedTransition', transition);
        }

        var authenticationRoute = this.authenticationRoute;
        return this.transitionTo(authenticationRoute);
      } else {
        return this._super.apply(this, arguments);
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var validator = _user.default;
      var changeset = new _emberChangeset.default(model.user, (0, _emberChangesetValidations.default)(validator), validator);
      controller.set('changeset', changeset);
    },
    actions: {
      editUser: function editUser()
      /*user*/
      {
        this.transitionTo({
          queryParams: {
            editUser: true
          }
        });
      },
      saveUser: function saveUser(changeset) {
        var _this2 = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            changeset.save().then(function () {
              changeset.rollback();

              _this2.transitionTo({
                queryParams: {
                  editUser: false
                }
              });
            });
          }
        });
      },
      cancelUser: function cancelUser(changeset) {
        changeset.rollback();
        this.transitionTo({
          queryParams: {
            editUser: false
          }
        });
      },
      logout: function logout() {
        return this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});