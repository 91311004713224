define("songbox/adapters/user", ["exports", "songbox/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord()
    /*modelName, snapshot*/
    {
      return this._super.apply(this, arguments).replace('users', 'register');
    },
    urlForUpdateRecord: function urlForUpdateRecord()
    /*modelName, snapshot*/
    {
      return this._super.apply(this, arguments).replace(/[0-9]+$/, 'current');
    }
  });

  _exports.default = _default;
});