define("songbox/pods/components/em-note/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/t4N5M6x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[9],[14,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/em-note/template.hbs"
    }
  });

  _exports.default = _default;
});