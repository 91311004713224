define("songbox/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2N5B5Ff1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"routeStyleNamespaceClassSet\"]],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"x-toasts\"],false],[0,\"\\n\\n\"],[1,[21,\"modal-target\"],false],[0,\"\\n\\n\"],[1,[21,\"ember-load-remover\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/application/template.hbs"
    }
  });

  _exports.default = _default;
});