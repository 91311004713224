define("songbox/services/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
    Creates a setting being stored
    @private
    @method setting
  */
  function setting(name) {
    return Ember.computed({
      get: function get()
      /*key*/
      {
        return this.get("_lsi.settings.".concat(name));
      },
      set: function set(_key, value) {
        this.set("_lsi.settings.".concat(name), value);
        return value;
      }
    });
  }

  var _default = Ember.Service.extend({
    _lsi: Ember.inject.service('local-settings'),
    // define settings here
    nightMode: setting('nightMode'),
    showChords: setting('showChords'),
    showHeader: setting('showHeader')
  });

  _exports.default = _default;
});