define("songbox/pods/components/app-sidebar/-main/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mFtks9Wu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"main\"],[9],[0,\"\\n  \"],[1,[27,\"from-elsewhere\",null,[[\"name\"],[\"sidebar\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/app-sidebar/-main/template.hbs"
    }
  });

  _exports.default = _default;
});