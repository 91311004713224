define("songbox/pods/song/edit/route", ["exports", "songbox/mixins/routes/model-changeset", "songbox/mixins/routes/dirty-changeset", "songbox/validations/song"], function (_exports, _modelChangeset, _dirtyChangeset, _song) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modelChangeset.default, _dirtyChangeset.default, {
    validator: _song.default,
    actions: {
      save: function save(changeset) {
        var _this = this;

        changeset.save().then(function (song) {
          _this.transitionTo('song', song);
        });
      },
      show: function show(song) {
        this.transitionTo('song', song);
      }
    }
  });

  _exports.default = _default;
});