define("songbox/pods/viewer/room/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    queryParams: {
      showMenu: 'open'
    },
    showMenu: false
  });

  _exports.default = _default;
});