define("songbox/pods/auth/register/route", ["exports", "songbox/mixins/routes/model-changeset", "songbox/validations/user"], function (_exports, _modelChangeset, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modelChangeset.default, {
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    validator: _user.default,
    model: function model() {
      return {
        modelName: 'user'
      };
    },
    actions: {
      doRegister: function doRegister(changeset) {
        var _this = this;

        var user = this.store.createRecord('user', changeset.get('change'));
        return user.save().then(function () {
          _this.transitionTo('auth.login');

          _this.flashMessages.success('Registered! Please login now');
        }).catch(function (resp) {
          _this.store.unloadRecord(user);

          resp.errors.forEach(function (error) {
            var attribute = Ember.String.camelize(error.source.pointer.split('/')[3]);
            var message = error.detail;
            changeset.pushErrors(attribute, message);
          });
        });
      }
    }
  });

  _exports.default = _default;
});