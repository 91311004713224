define("songbox/helpers/host-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hostUrl = hostUrl;
  _exports.default = void 0;

  function hostUrl()
  /*params, hash*/
  {
    if (window && window.location) {
      return window.location.origin;
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(hostUrl);

  _exports.default = _default;
});