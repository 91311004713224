define("songbox/services/ajax", ["exports", "ember-ajax/services/ajax", "songbox/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _ENV$api = _environment.default.api,
      host = _ENV$api.host,
      namespace = _ENV$api.namespace;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: host,
    namespace: namespace,
    headers: Ember.computed('session.data', {
      get: function get() {
        var headers = {
          'Accept': 'application/vnd.api+json'
        };

        var _this$get = this.get('session.data.authenticated'),
            access_token = _this$get.access_token;

        if (Ember.isPresent(access_token)) {
          headers['Authorization'] = "Bearer ".concat(access_token);
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});