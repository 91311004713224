define("songbox/pods/components/app-sidebar/-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DySljARn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"menu-close\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n    \"],[1,[27,\"svg-icon\",[\"ic_close_24px\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"songs\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",[\"ic_music_note_24px\"],null],false],[0,\"\\n        \"],[1,[27,\"t\",[\"app.link-songs\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"lists\"],null,{\"statements\":[[0,\"        \"],[1,[27,\"svg-icon\",[\"ic_queue_music_24px\"],null],false],[0,\"\\n        \"],[1,[27,\"t\",[\"app.link-lists\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/app-sidebar/-header/template.hbs"
    }
  });

  _exports.default = _default;
});