define("songbox/pods/components/item-list/list-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r/xmrYj3",
    "block": "{\"symbols\":[\"model\",\"index\",\"&default\"],\"statements\":[[4,\"vertical-collection\",[[23,[\"models\"]]],[[\"tagName\",\"bufferSize\",\"estimateHeight\",\"staticHeight\",\"renderAll\",\"renderFromLast\"],[\"ul\",1,60,true,true,false]],{\"statements\":[[4,\"item-list/list-items/list-item\",null,[[\"group\",\"model\",\"selected\",\"handle\"],[[23,[\"group\"]],[22,1,[]],[23,[\"selected\"]],\".handle\"]],{\"statements\":[[0,\"    \"],[14,3,[[27,\"hash\",null,[[\"text\",\"handle\",\"button-add\",\"button-remove\"],[[27,\"component\",[\"item-list/list-items/list-item/item-text\"],[[\"data-test-item-text\"],[1]]],[27,\"component\",[\"item-list/list-items/list-item/item-handle\"],[[\"data-test-item-handle\"],[1]]],[27,\"component\",[\"item-list/list-items/list-item/item-button\"],[[\"icon\",\"text\",\"data-test-item-button-add\"],[\"ic_add_circle_24px\",\"Add\",1]]],[27,\"component\",[\"item-list/list-items/list-item/item-button\"],[[\"icon\",\"text\",\"data-test-item-button-remove\"],[\"ic_remove_circle_24px\",\"Remove\",1]]]]]],[22,1,[]],[22,2,[]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/list-items/template.hbs"
    }
  });

  _exports.default = _default;
});