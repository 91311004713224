define("songbox/pods/components/broadcast-info/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    pubsub: Ember.inject.service(),
    current: Ember.inject.service(),
    attributeBindings: ['pubsub.statusMessage:title'],
    classNameBindings: ['pubsub.isSuccess:success', 'pubsub.isInfo:info', 'pubsub.isDanger:danger']
  });

  _exports.default = _default;
});