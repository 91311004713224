define("songbox/pods/app/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    queryParams: {
      // NOTE: disable query-param for now
      //showMenu: 'open'
      editUser: 'account'
    },
    showMenu: true,
    editUser: false
  });

  _exports.default = _default;
});