define("songbox/pods/components/songs-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    songs: null,
    actions: {
      selectSong: function selectSong(song) {
        this.onSelect(song);
      },
      removeSong: function removeSong(song) {
        this.onRemove(song);
      }
    }
  });

  _exports.default = _default;
});