define("songbox/pods/songs/import/route", ["exports", "jquery", "songbox/utils/promise-file-reader"], function (_exports, _jquery, _promiseFileReader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
  var _default = Ember.Route.extend({
    model: function model() {
      return {
        modelName: 'song' // used in ember-form-for

      };
    },
    actions: {
      doImport: function doImport() {
        var _this = this;

        var fileList = (0, _jquery.default)('input[type=file]')[0].files;
        Array.prototype.forEach.call(fileList, function (file) {
          switch (file.type) {
            case '':
              // opensong file
              (0, _promiseFileReader.readAsText)(file).then(function (fileContents) {
                var xmlDoc = _jquery.default.parseXML(fileContents);

                var $xml = (0, _jquery.default)(xmlDoc);

                var song = _this.store.createRecord('song');

                song.setProperties({
                  title: $xml.find('title').text(),
                  author: $xml.find('author').text(),
                  key: $xml.find('key').text(),
                  text: $xml.find('lyrics').text()
                });
                return song.save().catch(function () {
                  _this.store.unloadRecord(song);
                });
              });
              break;

            default:
              console.warn(file.type + ' not supported yet');
          }
        });
      },
      cancel: function cancel() {
        this.transitionTo('songs');
      }
    }
  });

  _exports.default = _default;
});