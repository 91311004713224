define("songbox/pods/components/x-toasts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mMR33PNr",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[4,\"each\",[[27,\"reverse\",[[23,[\"flashMessages\",\"arrangedQueue\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"x-toasts/x-toast\",null,[[\"content\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/x-toasts/template.hbs"
    }
  });

  _exports.default = _default;
});