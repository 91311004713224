define("songbox/pods/songs/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.findAll('song');
    },
    actions: {
      select: function select(song) {
        return this.transitionTo('song', song);
      },
      remove: function remove(song) {
        return song.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});