define("songbox/helpers/song-section-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.songSectionHeader = songSectionHeader;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function songSectionHeader(_ref
  /*, hash*/
  ) {
    var _ref2 = _slicedToArray(_ref, 1),
        abbr = _ref2[0];

    var replacements = {
      'C': 'Chorus',
      'V': 'Verse',
      'B': 'Bridge',
      'T': 'Tag',
      'P': 'Pre-Chorus'
    };
    var regexp = new RegExp("^([".concat(Object.keys(replacements).join(""), "])(.*)$"), "i");
    var abbArr = regexp.exec(abbr);

    if (Ember.isEmpty(abbArr)) {
      return abbr; // <- !!
    } // cleanup match


    abbArr.shift();

    if (Ember.isEmpty(abbArr[1])) {
      abbArr.pop();
    } // do replacement


    var char = abbArr[0].toUpperCase();
    abbArr[0] = replacements[char];
    /*
    # use i18n if available
    abbArr[0] = i18n.t "header.#{abbArr[0].toLowerCase()}" if i18n?
      */

    return abbArr.join(' ');
  }

  var _default = Ember.Helper.helper(songSectionHeader);

  _exports.default = _default;
});