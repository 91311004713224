define("songbox/pods/songs/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+FLFPWXC",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"em-header\",null,null,{\"statements\":[[1,[27,\"t\",[\"songs.new.header\"],null],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[27,\"song-form\",null,[[\"changeset\",\"onSave\",\"onCancel\"],[[23,[\"changeset\"]],[27,\"route-action\",[\"save\",[23,[\"changeset\"]]],null],[27,\"route-action\",[\"list\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/songs/new/template.hbs"
    }
  });

  _exports.default = _default;
});