define("songbox/pods/components/lists-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    lists: null,
    actions: {
      selectList: function selectList(list) {
        this.onSelect(list);
      },
      removeList: function removeList(list) {
        this.onRemove(list);
      }
    }
  });

  _exports.default = _default;
});