define("songbox/pods/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9vzc5yBU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"user-login-form\",null,[[\"changeset\",\"onSubmit\"],[[23,[\"changeset\"]],[27,\"route-action\",[\"doLogin\",[23,[\"changeset\"]]],null]]],{\"statements\":[[0,\"  \"],[4,\"link-to\",[\"auth.register\"],null,{\"statements\":[[0,\"Register\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/auth/login/template.hbs"
    }
  });

  _exports.default = _default;
});