define("songbox/services/pubsub", ["exports", "ember-phoenix/services/phoenix-socket", "songbox/config/environment"], function (_exports, _phoenixSocket, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var PhoenixSocketService = _phoenixSocket.default.extend({
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    statusCode: 0,
    statusMessage: '',
    isSuccess: Ember.computed.equal('statusCode', 2),
    isInfo: Ember.computed.equal('statusCode', 1),
    isDanger: Ember.computed.equal('statusCode', 0),
    init: function init() {
      var _this = this;

      // You may listen to open, "close" and "error"
      this._super.apply(this, arguments);

      this.on('open', function () {
        _this._success('Connection established!');
      });
      this.on('close', function () {
        _this._danger('Connection lost!');
      });
      this.on('error', function () {
        _this._danger('Error connecting!');
      });
    },
    connectUser: function connectUser() {
      var token = this.get('session.data').authenticated.access_token;
      var params = {
        token: token
      };
      return this.connect("".concat(_environment.default.ws.host, "/socket/user"), {
        params: params
      });
    },
    connectViewer: function connectViewer() {
      return this.connect("".concat(_environment.default.ws.host, "/socket/viewer"));
    },
    joinChannel: function joinChannel(name, params) {
      var _this2 = this;

      var socket = this.socket;
      (false && !(socket) && Ember.assert('must connect to a socket first', socket));
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var channel = socket.channel(name, params);
        channel.join().receive('ok', function () {
          _this2._success('Ready to broadcast!');

          resolve(channel);
        }).receive('error', function (_ref) {
          var reason = _ref.reason;

          _this2._danger('Cannot broadcast!');

          reject(reason);
        }).receive('timeout', function () {
          _this2._info('Networking issue. Still waiting...');
        });
      });
    },
    // status changes
    _success: function _success(message) {
      this.flashMessages.success(message);
      this.setProperties({
        statusCode: 2,
        statusMessage: message
      });
    },
    _info: function _info(message) {
      this.flashMessages.info(message);
      this.setProperties({
        statusCode: 1,
        statusMessage: message
      });
    },
    _danger: function _danger(message) {
      this.flashMessages.danger(message);
      this.setProperties({
        statusCode: 0,
        statusMessage: message
      });
    }
  }); // DummySocketService used while dev with `ember-cli-mirage` or while testing.
  // (better to inject somewhere?)


  var DummySocketService = Ember.Service.extend({
    connectUser: function connectUser() {
      return new Ember.RSVP.resolve({});
    },
    connectViewer: function connectViewer() {
      return new Ember.RSVP.resolve({});
    },
    joinChannel: function joinChannel()
    /*name, params*/
    {
      return new Ember.RSVP.resolve({
        push: function push() {},
        on: function on() {}
      });
    }
  });
  var SocketService;

  if (_environment.default['ember-cli-mirage'].enabled || _environment.default.environment === 'test') {
    SocketService = DummySocketService;
  } else {
    SocketService = PhoenixSocketService;
  }

  var _default = SocketService;
  _exports.default = _default;
});