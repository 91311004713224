define("songbox/pods/components/item-list/list-items/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ItemsComponent = Ember.Component.extend({
    models: null,
    group: null
  });
  ItemsComponent.reopenClass({
    positionalParams: ['models', 'group']
  });
  var _default = ItemsComponent;
  _exports.default = _default;
});