define("songbox/pods/songs/new/route", ["exports", "songbox/mixins/routes/model-changeset", "songbox/validations/song"], function (_exports, _modelChangeset, _song) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_modelChangeset.default, {
    validator: _song.default,
    model: function model() {
      return {
        modelName: 'song',
        // used in ember-form-for
        format: 'opensong'
      };
    },
    actions: {
      save: function save(changeset) {
        var _this = this;

        return changeset.validate().then(function () {
          if (changeset.get('isValid')) {
            var song = _this.store.createRecord('song', changeset.get('change'));

            song.save().then(function () {
              _this.transitionTo('song', song);
            });
          }
        });
      },
      list: function list() {
        this.transitionTo('songs');
      }
    }
  });

  _exports.default = _default;
});