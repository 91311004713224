define("songbox/pods/components/item-list/list-items/list-item/item-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cThTLjI9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"svg-icon\",[[23,[\"icon\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[1,[21,\"text\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/list-items/list-item/item-button/template.hbs"
    }
  });

  _exports.default = _default;
});