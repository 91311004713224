define("songbox/pods/components/settings-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "noG4FsPH",
    "block": "{\"symbols\":[\"t\"],\"statements\":[[4,\"x-toggle\",null,[[\"size\",\"value\",\"showLabels\",\"onToggle\"],[\"small\",[23,[\"setting\"]],true,[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"setting\"]]],null]],null]]],{\"statements\":[[0,\"  \"],[1,[22,1,[\"switch\"]],false],[0,\"\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"label\"]],\"expected `t.label` to be a contextual component but found a string. Did you mean `(component t.label)`? ('songbox/pods/components/settings-switch/template.hbs' @ L3:C5) \"],null]],[[\"value\"],[[27,\"not\",[[23,[\"setting\"]]],null]]],{\"statements\":[[0,\"    \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/settings-switch/template.hbs"
    }
  });

  _exports.default = _default;
});