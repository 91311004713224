define("songbox/adapters/application", ["exports", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "songbox/config/environment"], function (_exports, _jsonApi, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.api.host,
    namespace: _environment.default.api.namespace,
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    }
  });

  _exports.default = _default;
});