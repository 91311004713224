define("songbox/pods/components/item-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Pv52QKzn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[27,\"hash\",null,[[\"header\",\"filter\",\"actions\",\"items\"],[[27,\"component\",[\"item-list/list-header\"],null],[27,\"component\",[\"item-list/list-filter\"],null],[27,\"component\",[\"item-list/list-actions\"],null],[27,\"component\",[\"item-list/list-items\"],null]]]]]],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/item-list/template.hbs"
    }
  });

  _exports.default = _default;
});