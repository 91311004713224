define("songbox/pods/components/user-register-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Imu60TJ",
    "block": "{\"symbols\":[\"f\",\"&default\"],\"statements\":[[4,\"form-for\",[[23,[\"changeset\"]]],[[\"submit\"],[[23,[\"onSubmit\"]]]],{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"email-field\"]],\"expected `f.email-field` to be a contextual component but found a string. Did you mean `(component f.email-field)`? ('songbox/pods/components/user-register-form/template.hbs' @ L2:C4) \"],null],\"email\"],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"password-field\"]],\"expected `f.password-field` to be a contextual component but found a string. Did you mean `(component f.password-field)`? ('songbox/pods/components/user-register-form/template.hbs' @ L3:C4) \"],null],\"password\"],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"password-field\"]],\"expected `f.password-field` to be a contextual component but found a string. Did you mean `(component f.password-field)`? ('songbox/pods/components/user-register-form/template.hbs' @ L4:C4) \"],null],\"passwordConfirmation\"],null],false],[0,\"\\n  \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"submit\"]],\"expected `f.submit` to be a contextual component but found a string. Did you mean `(component f.submit)`? ('songbox/pods/components/user-register-form/template.hbs' @ L5:C4) \"],null],[27,\"t\",[\"auth.register.button\"],null]],null],false],[0,\"\\n  \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/user-register-form/template.hbs"
    }
  });

  _exports.default = _default;
});