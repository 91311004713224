define("songbox/pods/list/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kJWotbmk",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"em-header\",null,[[\"center\"],[true]],{\"statements\":[[0,\"Add or select Song on the left side.\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/list/index/template.hbs"
    }
  });

  _exports.default = _default;
});