define("songbox/pods/components/x-toasts/x-toast/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Ty3i11C",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,[\"content\",\"message\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/x-toasts/x-toast/template.hbs"
    }
  });

  _exports.default = _default;
});