define("songbox/pods/song/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      error: function error(_error
      /*, transition*/
      ) {
        var isNotFoundError = _error.errors.mapBy('status').includes("404");

        if (isNotFoundError) {
          this.transitionTo('songs');
        } else {
          // Let the route above this handle the error.
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});