define("songbox/utils/promise-file-reader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.readAsArrayBuffer = _exports.readAsText = _exports.readAsDataURL = void 0;

  // https://github.com/jahredhope/promise-file-reader
  function readAs(file, as) {
    if (!(file instanceof Blob)) {
      throw new TypeError('Must be a File or Blob');
    }

    return new Ember.RSVP.Promise(function (resolve, reject) {
      var reader = new FileReader();

      reader.onload = function (e) {
        return resolve(e.target.result);
      };

      reader.onerror = function (e) {
        return reject("Error reading ".concat(file.name, ": ").concat(e.target.result));
      };

      reader['readAs' + as](file);
    });
  }

  var readAsDataURL = function readAsDataURL(file) {
    return readAs(file, 'DataURL');
  };

  _exports.readAsDataURL = readAsDataURL;

  var readAsText = function readAsText(file) {
    return readAs(file, 'Text');
  };

  _exports.readAsText = readAsText;

  var readAsArrayBuffer = function readAsArrayBuffer(file) {
    return readAs(file, 'ArrayBuffer');
  };

  _exports.readAsArrayBuffer = readAsArrayBuffer;
  var _default = {
    readAsDataURL: readAsDataURL,
    readAsText: readAsText,
    readAsArrayBuffer: readAsArrayBuffer
  };
  _exports.default = _default;
});