define("songbox/mixins/routes/model-changeset", ["exports", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var validator = this.validator;
      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(validator), validator);
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});