define("songbox/pods/components/item-list/list-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    items: null,
    searchTerm: '',
    filtered: Ember.computed('items.[]', 'searchTerm', function () {
      var term = this.searchTerm;
      var attr = this.attr;

      if (Ember.isEmpty(term)) {
        return this.items;
      }

      var regexp = new RegExp(term, 'i');
      return this.items.filter(function (item) {
        return regexp.test(item.get(attr));
      });
    }),
    actions: {
      clearSearch: function clearSearch() {
        this.set('searchTerm', '');
      }
    }
  });

  _exports.default = _default;
});