define("songbox/pods/list/add/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      songId: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var songId = _ref.songId;
      var options = {
        reload: false,
        backgroundReload: true
      };
      return Ember.RSVP.hash({
        list: this.modelFor('list'),
        songs: this.store.findAll('song', options),
        song: songId && this.store.findRecord('song', songId)
      });
    },
    actions: {
      add: function add(list, song) {
        var item = this.store.createRecord('list-item', {
          list: list,
          song: song
        });
        return item.save();
      },
      select: function select(list, song) {
        this.transitionTo({
          queryParams: {
            songId: song.get('id')
          }
        });
      }
    }
  });

  _exports.default = _default;
});