define("songbox/pods/components/list-items-sidebar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    list: null,
    actions: {
      selectItem: function selectItem(item, index) {
        this.onSelect(item, index);
      },
      removeItem: function removeItem(item) {
        this.onRemove(item);
      },
      reorderItems: function reorderItems(itemModels, draggedModel) {
        this.onReorder(itemModels, draggedModel);
      }
    }
  });

  _exports.default = _default;
});