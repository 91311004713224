define("songbox/router", ["exports", "songbox/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.on('routeDidChange', function () {
        var page = _this.router.currentURL;
        var title = _this.router.currentRouteName || 'unknown';

        _this.metrics.trackPage({
          page: page,
          title: title
        });
      });
    }
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
      this.route('register');
    });
    this.route('viewer', {
      path: 'v'
    }, function () {
      this.route('not_found');
      this.route('room', {
        path: ':room_id'
      }, function () {});
    });
    this.route('app', {
      path: 'a'
    }, function () {
      this.route('songs', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('import');
        this.route('song', {
          path: ':song_id',
          resetNamespace: true
        }, function () {
          this.route('edit');
        });
      });
      this.route('lists', {
        resetNamespace: true
      }, function () {
        this.route('new');
        this.route('list', {
          path: ':list_id',
          resetNamespace: true
        }, function () {
          this.route('add');
          this.route('item', {
            path: ':position'
          }, function () {});
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});