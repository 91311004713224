define("songbox/pods/components/app-sidebar/-footer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H3G0OnGs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[9],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"screen-share\"],[9],[0,\"\\n      \"],[1,[21,\"broadcast-info\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[9],[0,\"\\n        \"],[1,[27,\"svg-icon\",[\"ic_person_outline_24px\"],null],false],[0,\"\\n        \"],[1,[27,\"t\",[\"app.link-account\"],null],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],[27,\"route-action\",[\"editUser\",[23,[\"model\",\"user\"]]],null]]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/app-sidebar/-footer/template.hbs"
    }
  });

  _exports.default = _default;
});