define("songbox/pods/components/broadcast-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ycHMac2N",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"svg-icon\",[\"ic_screen_share_24px\"],null],false],[0,\"\\n\"],[7,\"input\"],[11,\"class\",\"form-field--control\"],[12,\"value\",[27,\"concat\",[[27,\"host-url\",null,null],[27,\"href-to\",[\"viewer.room\",[23,[\"current\",\"room\",\"uid\"]]],null]],null]],[11,\"disabled\",\"\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/broadcast-info/template.hbs"
    }
  });

  _exports.default = _default;
});