define("songbox/pods/components/song-sheet/chordpro-sheet/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "avyieHqT",
    "block": "{\"symbols\":[\"line\",\"obj\"],\"statements\":[[4,\"each\",[[23,[\"sections\",\"parsedLines\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"line\"],[9],[0,\"\\n\"],[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[4,\"if\",[[27,\"and\",[[23,[\"showChords\"]],[22,2,[\"chord\"]]],null]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"chord\"],[9],[1,[22,2,[\"chord\"]],false],[10]],\"parameters\":[]},null],[7,\"span\"],[12,\"class\",[22,2,[\"type\"]]],[9],[1,[22,2,[\"lyrics\"]],false],[10],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/components/song-sheet/chordpro-sheet/template.hbs"
    }
  });

  _exports.default = _default;
});