define("songbox/utils/parsers", ["exports", "songbox/utils/parsers/opensong", "songbox/utils/parsers/chordpro"], function (_exports, _opensong, _chordpro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var parsers = {
    chordpro: _chordpro.parse,
    opensong: _opensong.default
  };
  var _default = parsers;
  _exports.default = _default;
});