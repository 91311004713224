define("songbox/pods/songs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MFrqAi4c",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"to-elsewhere\",null,[[\"named\",\"send\"],[\"sidebar\",[27,\"component\",[\"songs-sidebar\"],[[\"songs\",\"selected\",\"editMode\",\"onSelect\",\"onRemove\"],[[23,[\"model\"]],[23,[\"selected\"]],[23,[\"editMode\"]],[27,\"route-action\",[\"select\"],null],[27,\"route-action\",[\"remove\"],null]]]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "songbox/pods/songs/template.hbs"
    }
  });

  _exports.default = _default;
});