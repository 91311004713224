define("songbox/initializers/fastclick", ["exports", "ember-cli-fastclick/initializers/fastclick"], function (_exports, _fastclick) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _fastclick.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _fastclick.initialize;
    }
  });
});